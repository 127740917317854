import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default class AppelsCourts extends Component<{}, IAppelsCourtsDataStructure> {
  constructor(props:any) {
    super(props);
    this.state = {data: []};
  }
  componentDidMount() {
    fetch('https://ntx-crmv2-business.natixgroup.com/api/short_calls_date/2023-12-01/2023-12-31/0/5')
      .then((response) => response.json())
      .then((data) => {this.setState({ data })});
  }
  render() {
    const { data } = this.state;
    return (
      <ResponsiveContainer width="80%" height="80%">
        <LineChart width={500} height={300} data={data} margin={{top:5, right:30, left:20, bottom:5,}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hour" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="FKTEL_100000001" stroke="red"       />
        <Line type="monotone" dataKey="UNIMOB_73222"    stroke="green"     />
        <Line type="monotone" dataKey="FKTEL_100000010" stroke="red"       />
        <Line type="monotone" dataKey="UNIMOB_73204"    stroke="green"     />
        <Line type="monotone" dataKey="FKTEL_100000002" stroke="red"       />        
        </LineChart>
        </ResponsiveContainer>
    );
  }
}
interface IAppelsCourtsDataStructure {
  data: Object[];
}
