import React, { useEffect, useState,CSSProperties } from "react";
import { ICall } from "./ICall";
import   CallDetailsFromA2Billing  from "./CallDetailsFromA2Billing";
// return a simple component with a button
const LastCalls = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("https://ntx-crmv2-business.natixgroup.com/api/get-all-dids");
    const data = await response.json();
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row">
      <button onClick={fetchData}>Rafraichir</button>
      </div>
      <div className="row">
        {  loading ? ("Loading...") : data.map((item:string) => (<CallDetailsFromA2Billing did={item} />)) }
      </div>
    </div>
  );
}

export default LastCalls;
